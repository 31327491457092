import _ from "lodash";
import { createSelector } from "reselect";
import { RootState } from "../reducers";
import {
    getUserInfo,
    getCatalog,
    getErrorFromStore,
    getBundleMap,
} from "./commonSelectors";
import { selectedTeamIdSelector, selectedTeamSelector } from "./userSelectors";
import { getUserPermissionsSelector } from "./teamManagementSelectors";
import {
    BundleMap,
    ErrorPayload,
    FeaturePermission,
    TeamPermissions,
    catalogItem,
    channel,
    oAuthClientIds,
} from "../../models";

const getSignedIn = (state: RootState) => state.user.signedIn;
const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;
const getOAuthClientIds = (state: RootState) => state.user.oAuthClientIds;
const getLinkedChannel = (state: RootState) => state.twitch.linkedChannel;
const getUnlinkCompleted = (state: RootState) =>
    state.twitch.unlinkTwitchChannelCompleted;
const getTwitchLinkCompleted = (state: RootState) =>
    state.twitch.linkTwitchChannelCompleted;
const getTwitchLinkInProgress = (state: RootState) =>
    state.twitch.linkTwitchChannelInProgress;
const getHydrateTwitchChannelInProgress = (state: RootState) =>
    state.twitch.hydrateTwitchChannelInProgress;
const getTwitchChannelInProgress = (state: RootState) =>
    state.user.refreshInProgress || state.twitch.getTwitchChannelInProgress;
const getImageUploadInProgress = (state: RootState) =>
    state.imageUpload.inProgress;
const getImageUploadComplete = (state: RootState) =>
    state.imageUpload.uploadComplete;
const getFeaturePermissionsList = (state: RootState) =>
    state.featureAccess.featurePermissionsList;

export const profilePageSelector: any = createSelector<
    RootState,
    string,
    any,
    any
>(
    [
        getSignedIn,
        selectedTeamIdSelector,
        getSelectedArtistAsin,
        getCatalog,
        getLinkedChannel,
        getOAuthClientIds,
        getErrorFromStore,
        getUserPermissionsSelector,
        getUnlinkCompleted,
        getTwitchLinkCompleted,
        getTwitchLinkInProgress,
        getTwitchChannelInProgress,
        getHydrateTwitchChannelInProgress,
        getImageUploadInProgress,
        getImageUploadComplete,
        getFeaturePermissionsList,
        getBundleMap,
    ],
    (
        signedIn: boolean,
        teamId: string | undefined,
        selectedArtistAsin: string | undefined,
        catalog: Map<string, catalogItem>,
        channel: channel | undefined,
        oAuthClientIds: oAuthClientIds | undefined,
        error: ErrorPayload | undefined,
        userPermissions: TeamPermissions[],
        unlinkCompleted: boolean,
        linkCompleted: boolean,
        linkInProgress: boolean,
        getTwitchChannelInProgress: boolean,
        hydrateTwitchChannelInProgress: boolean,
        getImageUploadInProgress: boolean,
        getImageUploadComplete: boolean,
        getFeaturePermissionsList: FeaturePermission[],
        bundleMap: BundleMap
    ) => {
        return {
            signedIn: signedIn,
            teamId: teamId,
            selectedArtistAsin: selectedArtistAsin,
            catalog: catalog,
            error: error,
            userPermissions: userPermissions,
            unlinkCompleted: unlinkCompleted,
            channel: channel,
            oAuthClientIds: oAuthClientIds,
            twitchLinkCompleted: linkCompleted,
            twitchLinkInProgress: linkInProgress,
            getTwitchChannelInProgress: getTwitchChannelInProgress,
            hydrateTwitchChannelInProgress: hydrateTwitchChannelInProgress,
            inProgress: getImageUploadInProgress,
            uploadComplete: getImageUploadComplete,
            featurePermissionsList: getFeaturePermissionsList,
            bundleMap: bundleMap,
        };
    }
);
