import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown, Image } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router";
import { connect, useSelector, useDispatch } from "react-redux";
import {
    BundleMap,
    artist,
    EntityType,
    PathToPageIdMap,
} from "../../../models";
import { dropDownStyles } from "../../styles/buttonStyles";
import { IconsList, stringIds, ImageList, bundleIds } from "../../../assets";
import * as rootStyles from "../../styles";
import {
    getLocalizedString,
    paths,
    isHideNavBarPath,
    buildUIClickPayload,
} from "../../../utils";
import { RootState } from "../../../store/reducers";
import {
    commonSelectors,
    selectedArtistFromCatalogSelector,
} from "../../../store/selectors";
import { generateDropdownOption } from "./helpers";
import { clientMetricsActions, oAuthActions } from "../../../store/actions";
import { buttonIds } from "@amzn/ziggy-asset";

type StateProps = {
    selectedArtist?: artist;
    currentPath?: string;
    isPrivileged?: boolean;
};

const testIDPrefix = "ArtistImageDropdown";

export type ArtistImageDropdownProps = StateProps & RouteComponentProps<{}>;

const ArtistImgDropdown: React.FC<ArtistImageDropdownProps> = ({
    selectedArtist,
    currentPath,
    isPrivileged,
    history,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const hideNavBar = isHideNavBarPath(currentPath);
    const showNav = hideNavBar ? false : true;
    const dropdownStyle: React.CSSProperties = showNav
        ? dropDownStyles.dropdown
        : { ...dropDownStyles.dropdown, position: "static", marginRight: 16 };
    const dispatch = useDispatch();

    const helpClicked = () => {
        if (currentPath) {
            dispatch(
                clientMetricsActions.sendClientMetrics(
                    buildUIClickPayload(
                        buttonIds.ArtistAvatarMenu.help,
                        PathToPageIdMap.get(currentPath),
                        selectedArtist?.asin,
                        EntityType.ARTIST
                    )
                )
            );
        }
        dispatch(oAuthActions.setShowIntercomMessenger(true));
    };

    return (
        <StyledDropdown style={dropdownStyle} alignRight={true}>
            <Dropdown.Toggle
                id="profileMenu"
                className="avatarDropdown"
                style={{
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                }}
                disabled={!selectedArtist}
            >
                <Image
                    src={
                        selectedArtist?.images?.artSmall ||
                        ImageList.placeholder_artist
                    }
                    height={48}
                    width={48}
                    style={{ background: rootStyles.colors.background }}
                />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: 300 }}>
                {!isPrivileged && (
                    <Dropdown.Item
                        key={"ManageTeam"}
                        eventKey={"ManageTeam"}
                        onSelect={() => history?.push(paths.currentTeamMembers)}
                    >
                        {generateDropdownOption(
                            IconsList.ic_other_profiles,
                            getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                                stringId:
                                    stringIds.ManageCurrentTeam
                                        .manageTeamHeader,
                            }),
                            `${testIDPrefix}_ManageTeam`
                        )}
                    </Dropdown.Item>
                )}
                <Dropdown.Item
                    key={"SelectArtist"}
                    eventKey={"SelectArtist"}
                    onSelect={() => history?.push(paths.artistSelect)}
                    style={dropDownStyles.dropdownItem}
                >
                    {generateDropdownOption(
                        IconsList.navigation_refresh,
                        getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                            stringId:
                                stringIds.ManageCurrentTeam.selectArtistTitle,
                        }),
                        `${testIDPrefix}_SelectArtist`
                    )}
                </Dropdown.Item>
                <Dropdown.Item
                    key={"Help"}
                    eventKey={"Help"}
                    onSelect={() => helpClicked()}
                    style={dropDownStyles.dropdownItem}
                >
                    {generateDropdownOption(
                        IconsList.ic_help,
                        getLocalizedString(bundleMap, {
                            bundleId: bundleIds.CUSTOMER_SUPPORT_STRINGS,
                            stringId: stringIds.CustomerSupport.help_button,
                        }),
                        `${testIDPrefix}_Help`
                    )}
                </Dropdown.Item>
            </Dropdown.Menu>
        </StyledDropdown>
    );
};

const StyledDropdown = styled(Dropdown)`
    float: left;
    z-index: 1000;
    margin: auto 0px;
    @media (max-width: 992px) {
        margin: 0;
        right: 16px;
        top: 14px;
        position: fixed;
    }
`;

function mapStateToProps(state: RootState) {
    return {
        selectedArtist: selectedArtistFromCatalogSelector(state),
        currentPath: state.user.currentPath,
        isPrivileged: state.user.isPrivileged,
    };
}

export default withRouter(connect(mapStateToProps)(ArtistImgDropdown));
