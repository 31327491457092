import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import serialize from "serialize-javascript";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Area, Point } from "react-easy-crop/types";
import { isIOS, isMobile } from "react-device-detect";
import moment from "moment";
import Video from "./video/Video";
import { buttonIds, pageIds, LocalizedUrls } from "@amzn/ziggy-asset";
import { Container, Row, Col, Spinner, Modal, Toast } from "react-bootstrap";
import styled from "styled-components";
import * as rootStyles from "../../styles";
import {
    artist,
    channel,
    ErrorPayload,
    TeamPermissions,
    unlinkTwitchChannelPayload,
    linkTwitchChannelPayload,
    telemetryPayload,
    METRIC_KEYS,
    ImageType,
    artistImagePayload,
    hydrateCatalogPayload,
    oAuthClientIds,
    FeaturePermission,
    Feature,
    CatalogItemType,
    EntityType,
    clientMetricsPayload,
    BundleMap,
    catalogItem,
} from "../../../models";
import {
    RootState,
    userActions,
    telemetryActions,
    profilePageSelector,
    artistImageActions,
    twitchActions,
    catalogActions,
    clientMetricsActions,
} from "../../../store";
import { stringIds, IconsList, ImageList, bundleIds } from "../../../assets";
import {
    getLocalizedString,
    paths,
    TWITCH_SIGNUP_URL,
    BACKGROUND_IMAGE_MAX_BYTES,
    PROFILE_IMAGE_MAX_BYTES,
    BACKGROUND_IMAGE_MIN_WIDTH,
    PROFILE_IMAGE_MIN_WIDTH,
    IMAGE_COMPRESSION,
    APP_STORE_URL,
    PLAY_STORE_URL,
    buildUIClickPayload,
    getLocalizedUrl,
} from "../../../utils";
import {
    styledTitle,
    ErrorModal,
    Icon,
    ImageWithFallback,
    TwitchSuccessModal,
    TwitchInProgressModal,
    TwitchPostLaunchModal,
    InlineToast,
    CommonHeader,
    Loading,
    ImageCard,
    ArtistGradientBackgroundImage,
} from "../../components";
import { getThirdPartyEndpoints, generateState } from "../../../service/common";
import "./profile.css";
import {
    UploadModal,
    CropImageModal,
    FullScreenConfirmationModal,
    LearnMoreModal,
} from "../../components/common/modals";
import { spacers, colors, webFontSizes } from "../../styles";
import { MultiTitleDescriptionModal } from "../../components/common/modals";

const testIDPrefix = "ProfileScreen";
const metricPrefix = "profilePage";
const imageUploadArtistHandbookUrl = getLocalizedUrl(
    LocalizedUrls.ImageGuidelineUrl
);

type ViewProps = {
    partner: string;
    token: string;
    stateId?: string;
    nounce?: string;
};

type StateProps = {
    signedIn?: boolean;
    selectedArtistAsin?: string;
    catalog: Map<string, catalogItem>;
    channel?: channel;
    teamId?: string;
    unlinkCompleted: boolean;
    error?: ErrorPayload;
    userPermissions?: TeamPermissions[];
    twitchLinkCompleted: boolean;
    twitchLinkInProgress: boolean;
    getTwitchChannelInProgress: boolean;
    hydrateTwitchChannelInProgress: boolean;
    inProgress?: boolean;
    uploadComplete?: boolean;
    oAuthClientIds?: oAuthClientIds;
    featurePermissionsList: FeaturePermission[];
    bundleMap: BundleMap;
};

type DispatchProps = {
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
    unlinkTwitch: (payload: unlinkTwitchChannelPayload) => void;
    linkTwitch: (payload: linkTwitchChannelPayload) => void;
    getChannelInfo: (payload: unlinkTwitchChannelPayload) => void;
    uploadImage: (payload: artistImagePayload) => void;
    hydrateAsin: (payload: hydrateCatalogPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type TwitchState = {
    showAboutModal: boolean;
    showUnlinkConfirmationModal: boolean;
    didUnlink: boolean;
    showError: boolean;
    showTwitchSuccessModal: boolean;
    showTwitchUnlinkSucessToast: boolean;
};

type BlueprintsState = {
    showAboutSpotlightModal: boolean;
    showSpotlightLearnMoreModal: boolean;
    showSpotlightPermissionsText: boolean;
};

type State = BlueprintsState &
    TwitchState & {
        showUploadProfile: boolean;
        showUploadBackground: boolean;
        showCropProfile: boolean;
        showCropBackground: boolean;
        profileFileRef: React.RefObject<HTMLInputElement>;
        backgroundFileRef: React.RefObject<HTMLInputElement>;
        showUploadProfileSuccess: boolean;
        showUploadBackgroundSuccess: boolean;
        showLoading: boolean;
        showProfileAlert: boolean;
        profileAlertText?: string;
        showBackgroundAlert: boolean;
        backgroundAlertText?: string;
        croppedAreaPixels?: Area;
        croppedImageUrl?: string;
        selectedImage?: any;
        crop: Point;
        zoom: number;
        cookie?: any;
    };

class ProfileScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showSpotlightPermissionsText: false,
            showSpotlightLearnMoreModal: false,
            showAboutSpotlightModal: false,
            showAboutModal: false,
            showUnlinkConfirmationModal: false,
            didUnlink: false,
            showError: false,
            showTwitchSuccessModal: false,
            showTwitchUnlinkSucessToast: false,
            showCropProfile: false,
            showUploadBackground: false,
            showUploadProfile: false,
            showCropBackground: false,
            showUploadBackgroundSuccess: false,
            showUploadProfileSuccess: false,
            showLoading: false,
            showProfileAlert: false,
            showBackgroundAlert: false,
            profileFileRef: React.createRef(),
            backgroundFileRef: React.createRef(),
            crop: { x: 0, y: 0 },
            zoom: 1,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.updateCurrentPath(window.location.pathname);
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.profile],
            ]),
        });

        if (!this.props.selectedArtistAsin || !this.props.teamId) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.provideInformation],
            ]),
        });

        // [profile, link, authId]
        const splitPath = window.location.pathname.split("/");
        const token = splitPath[3];
        if (token) {
            // remove token from url
            if (window.history.pushState) {
                window.history.pushState({}, window.document.title, "/profile");
            }

            this.props.linkTwitch({
                artistAsin: this.props.selectedArtistAsin,
                authId: token,
                requestPath: paths.profile,
                teamId: this.props.teamId,
            });
        } else if (splitPath[2] === "link") {
            // user probably cancelled linking
            // remove token from url
            if (window.history.pushState) {
                window.history.pushState({}, window.document.title, "/profile");
            }
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // If we get an error, display it
        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (!this.props.selectedArtistAsin || !this.props.signedIn) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        // Did unlink
        if (!prevProps.unlinkCompleted && this.props.unlinkCompleted) {
            this.setState({
                didUnlink: true,
                showTwitchUnlinkSucessToast: true,
            });
        }

        if (
            !this.state.showTwitchSuccessModal &&
            this.props.twitchLinkCompleted &&
            !prevProps.twitchLinkCompleted
        ) {
            this.setState({ showTwitchSuccessModal: true });
        }

        // When uploading no longer in progress
        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({
                showLoading: false,
            });
        }

        // When background image uploading no longer in progress
        if (
            prevProps.inProgress &&
            !this.props.inProgress &&
            this.state.showCropBackground &&
            this.props.uploadComplete
        ) {
            this.setState({
                showCropBackground: false,
                showUploadBackgroundSuccess: true,
            });
        }

        // When profile image uploading no longer in progress
        if (
            prevProps.inProgress &&
            !this.props.inProgress &&
            this.state.showCropProfile &&
            this.props.uploadComplete
        ) {
            this.setState({
                showCropProfile: false,
                showUploadProfileSuccess: true,
            });
        }
    }

    render() {
        const hydratedArtist = this.props.catalog.get(
            this.props.selectedArtistAsin || ""
        ) as artist;

        const time = moment();
        if (
            hydratedArtist &&
            hydratedArtist.asin &&
            time.isAfter(hydratedArtist.ttl)
        ) {
            this.props.hydrateAsin({
                asins: [this.props.selectedArtistAsin || ""],
                type: CatalogItemType.Artists,
            });
        }

        const importantDetailsPoints = [
            {
                text: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.importantDetailsPoint2,
                }),
                textLink: undefined,
            },

            {
                text: `${getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.importantDetailsPoint3,
                })} `,
                textLink: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId:
                        stringIds.Spotlight.importantDetailsPoint3LearnMore,
                }),
            },
        ];

        const mediumSpacer = (
            <div style={{ flex: 1, minHeight: rootStyles.spacers.medium }} />
        );

        const spotlightLearnMoreBody = () => {
            return (
                <Row>
                    <span
                        style={{
                            ...rootStyles.textStyles.secondary,
                            color: rootStyles.colors.primary,
                        }}
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SPOTLIGHT_STRINGS,
                            stringId:
                                stringIds.Spotlight.learnMoreDialogParagraph1,
                        })}
                        {mediumSpacer}
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SPOTLIGHT_STRINGS,
                            stringId:
                                stringIds.Spotlight.learnMoreDialogParagraph3,
                        })}
                    </span>
                </Row>
            );
        };

        const renderListOfImportantDetails = importantDetailsPoints.map(
            ({ text, textLink }) => (
                <li
                    style={{
                        ...rootStyles.textStyles.tertiary,
                        fontSize: webFontSizes.t4,
                        flex: 1,
                        whiteSpace: "nowrap",
                    }}
                >
                    {text}
                    {textLink && (
                        <a
                            style={clickableTextStyle}
                            onClick={this.toggleSpotlightLearnMoreModal}
                            target="_blank"
                        >
                            {textLink}
                        </a>
                    )}
                </li>
            )
        );

        const spotlightTitleDescriptionPairs = [
            [
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalQuestion1,
                }),
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalAnswer1,
                }),
            ],
            [
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalQuestion2,
                }),
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalAnswer2,
                }),
            ],
            [
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalQuestion3,
                }),
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalAnswer3,
                }),
            ],
            [
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalQuestion4,
                }),
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalAnswer4,
                }),
            ],
            [
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalQuestion5,
                }),
                getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalAnswer5,
                }),
            ],
        ];
        const linkUnlinkButton = this.getTwitchCard(this.props.channel);
        const hasManageTwitchPermission = this.props.userPermissions?.includes(
            TeamPermissions.ManageTwitch
        );
        const hasUploadImagePermission = this.props.userPermissions?.includes(
            TeamPermissions.UploadImage
        );
        const hasManageSpotlightPermission =
            this.props.teamId === "super-user-team" ||
            this.props.userPermissions?.includes(
                TeamPermissions.ManageBluePrints
            );

        const channelLinked = !!this.props.channel;

        const twitchPostLaunchModal = (
            <TwitchPostLaunchModal
                isVisible={this.state.showAboutModal}
                onDismiss={() => this.setState({ showAboutModal: false })}
                primaryButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.TWITCH_STRINGS,
                    stringId:
                        channelLinked || !hasManageTwitchPermission
                            ? stringIds.Twitch.modalSeeLivestreamResource
                            : stringIds.Twitch.connectLabel,
                })}
                primaryButtonAction={
                    channelLinked || !hasManageTwitchPermission
                        ? this.seeLivestreamResources
                        : this.linkTwitch
                }
                secondaryButtonText={
                    !hasManageTwitchPermission
                        ? undefined
                        : channelLinked
                        ? undefined
                        : getLocalizedString(this.props.bundleMap, {
                              bundleId: bundleIds.TWITCH_STRINGS,
                              stringId: stringIds.Twitch.modalSignupButton,
                          })
                }
                secondaryButtonAction={
                    !hasManageTwitchPermission
                        ? undefined
                        : channelLinked
                        ? this.seeLivestreamResources
                        : () => window.open(TWITCH_SIGNUP_URL, "_blank")
                }
                bundleMap={this.props.bundleMap}
            />
        );

        const spotlightLearnMoreModal = (
            <LearnMoreModal
                isVisible={this.state.showSpotlightLearnMoreModal}
                title={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.learnMoreDialogTitle,
                })}
                body={spotlightLearnMoreBody}
                acceptbuttonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId:
                        stringIds.Spotlight.learnMoreDialogCloseButtonText,
                })}
                moreInformationButtonText={getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.SPOTLIGHT_STRINGS,
                        stringId:
                            stringIds.Spotlight
                                .learnMoreDialogLearnMoreButtonText,
                    }
                )}
                moreInformationButtonAction={() =>
                    window.open(
                        getLocalizedUrl(LocalizedUrls.SpotlightLearnMore),
                        "_blank"
                    )
                }
                modalWidth="85%"
                accept={this.toggleSpotlightLearnMoreModal}
                onDismiss={this.toggleSpotlightLearnMoreModal}
                id={`${testIDPrefix}_SpotlightLearnMoreModal`}
            />
        );

        const canAccessSpotlight =
            this.props.featurePermissionsList?.length !== 0 &&
            this.props.featurePermissionsList?.find(
                (permission) => permission.feature === Feature.Spotlight
            );

        const spotlightAboutModal = (
            <MultiTitleDescriptionModal
                isVisible={this.state.showAboutSpotlightModal}
                onDismiss={this.onConfirmDismissAboutBlueprintsModal}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SPOTLIGHT_STRINGS,
                    stringId: stringIds.Spotlight.aboutModalOkayButtonText,
                })}
                confirmButtonAction={this.onConfirmDismissAboutBlueprintsModal}
                titleDescriptionPairs={spotlightTitleDescriptionPairs}
                id={testIDPrefix + "SpotlightAboutModal"}
            />
        );

        let twitchModal;
        if (this.props.twitchLinkCompleted) {
            twitchModal = (
                <TwitchSuccessModal
                    isVisible={this.state.showTwitchSuccessModal}
                    channel={this.props.channel}
                    onDismiss={this.linkingComplete}
                    bundleMap={this.props.bundleMap}
                />
            );
        } else if (this.props.twitchLinkInProgress) {
            twitchModal = (
                <TwitchInProgressModal
                    isVisible={
                        !this.state.showTwitchSuccessModal &&
                        this.props.twitchLinkInProgress
                    }
                    bundleMap={this.props.bundleMap}
                />
            );
        }

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <ArtistGradientBackgroundImage artist={hydratedArtist} />

                <CommonHeader
                    title={
                        hydratedArtist?.title ||
                        getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.ACCOUNT_STRINGS,
                            stringId: stringIds.Account.overview,
                        })
                    }
                    subtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                        stringId: stringIds.ClaimThisArtist.artist,
                    })}
                    id={`${testIDPrefix}_Header`}
                    hideDatePicker={true}
                />

                {/* IMAGE SECTION */}
                <Row style={{ marginTop: rootStyles.spacers.large }}>
                    <Col>
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PROFILE_STRINGS,
                                stringId: stringIds.Profile.detail,
                            })}
                        </styledTitle.h4>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginBottom: rootStyles.spacers.medium,
                    }}
                >
                    <Col>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PROFILE_STRINGS,
                                stringId: stringIds.Profile.description,
                            })}
                        </span>
                    </Col>
                </Row>

                {isMobile && (
                    <Row>
                        <Col>
                            <Toast style={toastContainerStyle}>
                                <Toast.Body style={toastBodyStyle}>
                                    <p style={textStyle}>
                                        <a
                                            href={
                                                isIOS
                                                    ? APP_STORE_URL
                                                    : PLAY_STORE_URL
                                            }
                                            style={hrefTextStyle}
                                        >
                                            {getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.PROFILE_STRINGS,
                                                    stringId:
                                                        stringIds.Profile
                                                            .downloadApp,
                                                }
                                            ) + " "}
                                        </a>
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.PROFILE_STRINGS,
                                                stringId:
                                                    stringIds.Profile.update,
                                            }
                                        )}
                                    </p>
                                </Toast.Body>
                            </Toast>
                        </Col>
                    </Row>
                )}

                {!isMobile && (
                    <Row
                        style={{
                            maxWidth: 800,
                            gap: rootStyles.spacers.medium,
                            paddingLeft: 15, // matches bootstrap default applied to the rest of the page
                            paddingRight: 15, // matches bootstrap default applied to the rest of the page
                        }}
                    >
                        <FlexibleCol>
                            <ImageCard
                                cardStyle={twitchButtonStyle}
                                image={hydratedArtist?.images?.artSmall}
                                imageFallback={ImageList.placeholder_artist}
                                imagePrimaryText={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.PROFILE_STRINGS,
                                        stringId:
                                            stringIds.Profile.profilePhoto,
                                    }
                                )}
                                imageSecondaryText={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.PROFILE_STRINGS,
                                        stringId:
                                            stringIds.Profile
                                                .profileDescription,
                                    }
                                )}
                                rightIcon={
                                    !!hasUploadImagePermission
                                        ? IconsList.action_edit
                                        : undefined
                                }
                                testIDPrefix={testIDPrefix + "_ProfileImage"}
                                onClick={
                                    hasUploadImagePermission
                                        ? this.showUploadProfile
                                        : undefined
                                }
                            />
                        </FlexibleCol>
                        <FlexibleCol>
                            {hydratedArtist?.backgroundImages && (
                                <ImageCard
                                    cardStyle={twitchButtonStyle}
                                    image={
                                        hydratedArtist?.backgroundImages
                                            ?.artSmall
                                    }
                                    imageFallback={ImageList.placeholder_artist}
                                    imageStyle={
                                        rootStyles.imageStyles.miniSquareImage
                                    }
                                    imagePrimaryText={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId: bundleIds.PROFILE_STRINGS,
                                            stringId:
                                                stringIds.Profile.background,
                                        }
                                    )}
                                    imageSecondaryText={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId: bundleIds.PROFILE_STRINGS,
                                            stringId:
                                                stringIds.Profile
                                                    .backgroundDescription,
                                        }
                                    )}
                                    rightIcon={
                                        !!hasUploadImagePermission
                                            ? IconsList.action_edit
                                            : undefined
                                    }
                                    testIDPrefix={
                                        testIDPrefix + "_BackgroundImage"
                                    }
                                    onClick={
                                        hasUploadImagePermission
                                            ? this.showUploadBackground
                                            : undefined
                                    }
                                />
                            )}
                            {!hydratedArtist?.backgroundImages && (
                                <div
                                    id={`${testIDPrefix}_BackgroundLabel${
                                        hasUploadImagePermission
                                            ? ""
                                            : "NoPermission"
                                    }`}
                                    style={{
                                        ...twitchButtonStyle,
                                        cursor: hasUploadImagePermission
                                            ? "pointer"
                                            : "default",
                                    }}
                                    onClick={
                                        hasUploadImagePermission
                                            ? () =>
                                                  this.setState({
                                                      showUploadBackground:
                                                          true,
                                                  })
                                            : undefined
                                    }
                                >
                                    <Row
                                        style={{
                                            alignItems: "center",
                                            height: 56,
                                        }}
                                    >
                                        {hasUploadImagePermission && (
                                            <Col xs={3}>
                                                <div
                                                    style={
                                                        twitchIconContainerStyle
                                                    }
                                                >
                                                    <Icon
                                                        size={24}
                                                        color={"white"}
                                                        icon={
                                                            IconsList.action_add
                                                        }
                                                        id={`${testIDPrefix}_BackgroundImageAdd`}
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                        <Col>
                                            <Row>
                                                <span
                                                    style={{
                                                        fontSize:
                                                            rootStyles
                                                                .webFontSizes
                                                                .t1,
                                                        textTransform:
                                                            "capitalize",
                                                        margin: 0,
                                                        padding: 0,
                                                        color: rootStyles.colors
                                                            .primary,
                                                    }}
                                                >
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.ACCOUNT_STRINGS,
                                                            stringId:
                                                                hasUploadImagePermission
                                                                    ? stringIds
                                                                          .Account
                                                                          .backgroundImage
                                                                    : stringIds
                                                                          .Account
                                                                          .noBackground,
                                                        }
                                                    )}
                                                </span>
                                            </Row>
                                            <Row>
                                                <span
                                                    style={{
                                                        ...rootStyles.textStyles
                                                            .secondary,
                                                        marginTop:
                                                            rootStyles.spacers
                                                                .micro,
                                                    }}
                                                >
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.ACCOUNT_STRINGS,
                                                            stringId:
                                                                hasUploadImagePermission
                                                                    ? stringIds
                                                                          .Account
                                                                          .uploadBackground
                                                                    : stringIds
                                                                          .Account
                                                                          .contactAdmin,
                                                        }
                                                    )}
                                                </span>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </FlexibleCol>
                    </Row>
                )}

                <UploadModal
                    labelText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.label,
                    })}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.uploadProfile,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.file,
                    })}
                    uploadButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.select,
                    })}
                    uploadButtonAction={this.onSelectProfile}
                    onDismiss={this.onDismissUploadProfile}
                    isVisible={this.state.showUploadProfile}
                    id={testIDPrefix + "_UploadProfileModal"}
                />

                <input
                    accept=".jpeg,.jpg,.png"
                    ref={this.state.profileFileRef}
                    type="file"
                    style={{ display: "none" }}
                    value={""}
                    onChange={this.cropProfile}
                />

                <UploadModal
                    labelText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.label,
                    })}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.uploadBackground,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.backgroundFile,
                    })}
                    uploadButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.select,
                    })}
                    uploadButtonAction={this.onSelectBackground}
                    onDismiss={this.onDismissUploadBackground}
                    isVisible={this.state.showUploadBackground}
                    id={testIDPrefix + "_UploadBackgroundModal"}
                />

                <input
                    accept=".jpeg,.jpg,.png"
                    ref={this.state.backgroundFileRef}
                    type="file"
                    style={{ display: "none" }}
                    value={""}
                    onChange={this.cropBackground}
                />

                <CropImageModal
                    labelText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.instruction,
                    })}
                    cropShape="round"
                    image={this.state.selectedImage}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={1}
                    onZoomIn={this.onZoomIn}
                    onZoomOut={this.onZoomOut}
                    onCropChange={this.onCropChange}
                    onZoomChange={this.onZoomChange}
                    onCropComplete={this.onProfileCropComplete}
                    showAlert={this.state.showProfileAlert}
                    alertText={this.state.profileAlertText}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.uploadProfile,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.uploadNote,
                    })}
                    terms={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.terms,
                    })}
                    guidelines={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.uploadNoteNext,
                    })}
                    artistHandbookDescritpion={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.ACCOUNT_STRINGS,
                            stringId: stringIds.Account.artistHandbook,
                        }
                    )}
                    artistHandbookUrl={imageUploadArtistHandbookUrl}
                    uploadButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.selectNew,
                    })}
                    uploadButtonAction={this.onSelectProfile}
                    saveButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.upload,
                    })}
                    saveButtonAction={() =>
                        this.uploadCroppedImage(ImageType.ProfileImage)
                    }
                    onDismiss={this.onDismissCropProfile}
                    isVisible={this.state.showCropProfile}
                    id={testIDPrefix + "_CropProfileImageModal"}
                />

                <CropImageModal
                    labelText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.instruction,
                    })}
                    cropShape="rect"
                    image={this.state.selectedImage}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={4 / 3}
                    onZoomIn={this.onZoomIn}
                    onZoomOut={this.onZoomOut}
                    onCropChange={this.onCropChange}
                    onZoomChange={this.onZoomChange}
                    onCropComplete={this.onBackgroundCropComplete}
                    showAlert={this.state.showBackgroundAlert}
                    alertText={this.state.backgroundAlertText}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.uploadBackground,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.uploadNote,
                    })}
                    terms={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.terms,
                    })}
                    guidelines={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.uploadNoteNext,
                    })}
                    artistHandbookDescritpion={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.ACCOUNT_STRINGS,
                            stringId: stringIds.Account.artistHandbook,
                        }
                    )}
                    artistHandbookUrl={imageUploadArtistHandbookUrl}
                    uploadButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.selectNew,
                    })}
                    uploadButtonAction={this.onSelectBackground}
                    saveButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.upload,
                    })}
                    saveButtonAction={() =>
                        this.uploadCroppedImage(ImageType.BackgroundImage)
                    }
                    onDismiss={this.onDismissCropBackground}
                    isVisible={this.state.showCropBackground}
                    id={testIDPrefix + "_CropBackgroundImageModal"}
                />

                <Modal
                    show={this.state.showLoading}
                    style={rootStyles.containerStyles.modal}
                    size="lg"
                >
                    <Modal.Body>
                        <Loading />
                    </Modal.Body>
                </Modal>

                <FullScreenConfirmationModal
                    imageSource={this.state.croppedImageUrl}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.success,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.profileSuccess,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.PROFILE_STRINGS,
                            stringId: stringIds.Profile.close,
                        }
                    )}
                    confirmButtonAction={this.onConfirmUploadProfileSuccess}
                    onDismiss={this.onConfirmUploadProfileSuccess}
                    isVisible={this.state.showUploadProfileSuccess}
                    id={testIDPrefix + "_ProfileSuccessConfirmationModal"}
                />

                <FullScreenConfirmationModal
                    imageSource={this.state.croppedImageUrl}
                    imageStyle={rectImage}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.success,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.backgroundSuccess,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.PROFILE_STRINGS,
                            stringId: stringIds.Profile.close,
                        }
                    )}
                    confirmButtonAction={this.onConfirmUploadBackgroundSuccess}
                    onDismiss={this.onConfirmUploadBackgroundSuccess}
                    isVisible={this.state.showUploadBackgroundSuccess}
                    id={testIDPrefix + "_BackgroundSuccessConfirmationModal"}
                />

                {/* TWITCH SECTION */}
                {this.state.showTwitchUnlinkSucessToast ? (
                    <Row>
                        <InlineToast
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.TWITCH_STRINGS,
                                stringId: stringIds.Twitch.unlinkModalToast,
                            })}
                            onDismiss={() =>
                                this.setState({
                                    showTwitchUnlinkSucessToast: false,
                                })
                            }
                            id={`${testIDPrefix}_UnlinkedToast`}
                        />
                    </Row>
                ) : null}
                <Row
                    style={{
                        marginTop: rootStyles.spacers.huge,
                        marginBottom: rootStyles.spacers.medium,
                        justifyContent: "center",
                    }}
                >
                    <Col style={{ paddingRight: 0 }}>
                        <Row>
                            <styledTitle.h4>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.TWITCH_STRINGS,
                                    stringId: stringIds.Twitch.goLiveLabel,
                                })}
                            </styledTitle.h4>
                        </Row>
                        <Row>
                            <span style={rootStyles.textStyles.secondary}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.TWITCH_STRINGS,
                                    stringId: stringIds.Twitch.goLiveSubtitle,
                                })}
                            </span>
                            <Col
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Icon
                                    icon={IconsList.ic_help}
                                    size={rootStyles.icons.tiny}
                                    id={`${testIDPrefix}_AboutTwitch`}
                                    onClick={() => this.toggleAboutModal()}
                                    style={{
                                        opacity: rootStyles.glass._4,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {/* Go live from twitch button */}
                        {linkUnlinkButton}
                    </Col>
                </Row>

                {/* About twitch modal */}
                {twitchPostLaunchModal}
                {twitchModal}
                {/* Unlink twtich modal */}
                <FullScreenConfirmationModal
                    isVisible={this.state.showUnlinkConfirmationModal}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.TWITCH_STRINGS,
                        stringId: stringIds.Twitch.unlinkModalTitle,
                    })}
                    footer={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.TWITCH_STRINGS,
                        stringId: stringIds.Twitch.unlinkModalFooter,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.TWITCH_STRINGS,
                        stringId: stringIds.Twitch.unlinkModalDescription,
                    })}
                    cancelButtonAction={() =>
                        this.setState({ showUnlinkConfirmationModal: false })
                    }
                    cancelButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.GENERIC_STRINGS,
                        stringId: stringIds.Generic.cancel,
                    })}
                    confirmButtonAction={this.unlinkTwitch}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.TWITCH_STRINGS,
                            stringId: stringIds.Twitch.unlinkModalButton,
                        }
                    )}
                    icon={IconsList.action_removeInline}
                    onDismiss={() =>
                        this.setState({ showUnlinkConfirmationModal: false })
                    }
                    key="unlinkModal"
                    id={`${testIDPrefix}_UnlinkTwitchModal`}
                />
                <ErrorModal
                    isVisible={this.state.showError}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ERRORS_STRINGS,
                        stringId: stringIds.Errors.errorTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.TWITCH_STRINGS,
                        stringId: stringIds.Twitch.error,
                    })}
                    onDismiss={this.dismissError}
                />

                {/* Blueprint SECTION */}
                {canAccessSpotlight && (
                    <>
                        <Row
                            style={{
                                marginTop: rootStyles.spacers.huge,
                                marginBottom: rootStyles.spacers.medium,
                            }}
                        >
                            <Col style={{ paddingRight: 0 }}>
                                <Row>
                                    <styledTitle.h4>
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.SPOTLIGHT_STRINGS,
                                                stringId:
                                                    stringIds.Spotlight
                                                        .artistSpotlightLabel,
                                            }
                                        )}
                                    </styledTitle.h4>
                                </Row>
                                <Row>
                                    <span
                                        style={rootStyles.textStyles.secondary}
                                    >
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.SPOTLIGHT_STRINGS,
                                                stringId:
                                                    stringIds.Spotlight
                                                        .artistSpotlightSubtitle,
                                            }
                                        )}
                                    </span>
                                    <Col
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Icon
                                            icon={IconsList.ic_help}
                                            size={rootStyles.icons.tiny}
                                            id={`${testIDPrefix}_AboutSpotlight`}
                                            onClick={() =>
                                                this.toggleAboutSpotlightModal()
                                            }
                                            style={{
                                                opacity: rootStyles.glass._4,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: rootStyles.spacers.medium,
                                paddingLeft: 15, // matches bootstrap default applied to the rest of the page
                                paddingRight: 15, // matches bootstrap default applied to the rest of the page
                            }}
                        >
                            <FlexibleCol>
                                <div
                                    style={{
                                        ...twitchButtonStyle,
                                        cursor: hasManageSpotlightPermission
                                            ? "pointer"
                                            : "default",
                                    }}
                                    onClick={
                                        hasManageSpotlightPermission
                                            ? this.onShowBlueprints
                                            : undefined
                                    }
                                >
                                    <Row
                                        style={{
                                            alignItems: "center",
                                            height: 56,
                                        }}
                                    >
                                        {hasManageSpotlightPermission && (
                                            <Col xs={3}>
                                                <div
                                                    style={
                                                        twitchIconContainerStyle
                                                    }
                                                >
                                                    <Icon
                                                        size={24}
                                                        icon={
                                                            IconsList.ic_spotlight
                                                        }
                                                        id={`${testIDPrefix}_SpotlightIcon`}
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                        <Col>
                                            <Row
                                                style={{
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <span style={spanStyle}>
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.SPOTLIGHT_STRINGS,
                                                            stringId:
                                                                stringIds
                                                                    .Spotlight
                                                                    .manageSpotlight,
                                                        }
                                                    )}
                                                </span>
                                                {!hasManageSpotlightPermission && (
                                                    <span
                                                        style={{
                                                            ...rootStyles
                                                                .textStyles
                                                                .secondary,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        {getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.TWITCH_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .Twitch
                                                                        .contactAdminToLink,
                                                            }
                                                        )}
                                                    </span>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={videoPlayerContainerStyle}>
                                    <Video
                                        videoURL={getLocalizedUrl(
                                            LocalizedUrls.SpotlightExplainerVideoURL
                                        )}
                                        videoThumbnail={
                                            ImageList.spotlight_Titlecard
                                        }
                                        userAction={this.props.userAction}
                                        artistAsin={hydratedArtist?.asin}
                                        id={`${testIDPrefix}_SpotlightExplainerVideo`}
                                    ></Video>
                                </div>
                            </FlexibleCol>
                            {/* IMPORTANT DETAILS SECTION */}
                            <FlexibleCol
                                style={{
                                    ...twitchButtonWidth,
                                    paddingLeft: 0,
                                    flex: 1,
                                }}
                            >
                                <div style={informationCardContainerStyle}>
                                    <Row
                                        style={{
                                            flexDirection: "row",
                                            height: 56,
                                        }}
                                    >
                                        <div style={importantDetailsSpanStyle}>
                                            {getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.SPOTLIGHT_STRINGS,
                                                    stringId:
                                                        stringIds.Spotlight
                                                            .importantDetailsTitle,
                                                }
                                            )}
                                        </div>
                                        <Row style={{ flex: 1 }}>
                                            {renderListOfImportantDetails}
                                        </Row>
                                    </Row>
                                </div>
                            </FlexibleCol>
                        </Row>
                        {spotlightLearnMoreModal}
                        {spotlightAboutModal}
                    </>
                )}
            </Container>
        );
    }

    private onConfirmDismissAboutBlueprintsModal = () => {
        this.setState({ showAboutSpotlightModal: false });
    };

    private getTwitchCard = (channel?: channel) => {
        if (
            this.props.getTwitchChannelInProgress ||
            this.props.hydrateTwitchChannelInProgress
        ) {
            return (
                <div style={{ ...twitchButtonStyle, cursor: "default" }}>
                    <Row style={{ alignItems: "center" }}>
                        <Col style={{ textAlign: "center" }}>
                            <Spinner animation="border" variant="light" />
                        </Col>
                    </Row>
                </div>
            );
        }

        const hasManageTwitchPermission = this.props.userPermissions?.includes(
            TeamPermissions.ManageTwitch
        );
        if (!channel) {
            return (
                <div
                    id={`${testIDPrefix}_TwitchLinkButton${
                        hasManageTwitchPermission ? "" : "NoPermission"
                    }`}
                    style={{
                        ...twitchButtonStyle,
                        cursor: hasManageTwitchPermission
                            ? "pointer"
                            : "default",
                    }}
                    onClick={
                        hasManageTwitchPermission ? this.linkTwitch : undefined
                    }
                >
                    <Row style={{ alignItems: "center", minHeight: 56 }}>
                        {hasManageTwitchPermission && (
                            <Col xs={3}>
                                <div style={twitchIconContainerStyle}>
                                    <Icon
                                        size={24}
                                        color={"white"}
                                        icon={IconsList.action_add}
                                        id={`${testIDPrefix}_LinkTwitchIcon`}
                                    />
                                </div>
                            </Col>
                        )}
                        <Col>
                            <Row>
                                <span
                                    style={{
                                        fontSize: rootStyles.webFontSizes.t1,
                                        textTransform: "capitalize",
                                        margin: 0,
                                        padding: 0,
                                        color: rootStyles.colors.primary,
                                        flex: 1,
                                    }}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId: bundleIds.TWITCH_STRINGS,
                                        stringId: hasManageTwitchPermission
                                            ? stringIds.Twitch.connectLabel
                                            : stringIds.Twitch.noChannelLinked,
                                    })}
                                </span>
                            </Row>
                            {!hasManageTwitchPermission && (
                                <Row>
                                    <span
                                        style={{
                                            ...rootStyles.textStyles.secondary,
                                            marginTop: rootStyles.spacers.micro,
                                        }}
                                    >
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.TWITCH_STRINGS,
                                                stringId:
                                                    stringIds.Twitch
                                                        .contactAdminToLink,
                                            }
                                        )}
                                    </span>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        ...twitchButtonStyle,
                        cursor: hasManageTwitchPermission
                            ? "pointer"
                            : "default",
                    }}
                    onClick={
                        hasManageTwitchPermission
                            ? () =>
                                  this.setState({
                                      showUnlinkConfirmationModal: true,
                                  })
                            : undefined
                    }
                >
                    <Row style={{ alignItems: "center" }}>
                        <Col xs={3}>
                            <ImageWithFallback
                                style={{
                                    ...rootStyles.imageStyles.miniRoundImage,
                                    margin: 0,
                                }}
                                source={channel.profileImageUrl}
                                id={testIDPrefix + "_ChannelProfileImage"}
                                fallback={ImageList.placeholder_artist}
                            />
                        </Col>
                        <Col>
                            <Row>
                                <span style={rootStyles.textStyles.label}>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId: bundleIds.TWITCH_STRINGS,
                                        stringId: stringIds.Twitch.channelLabel,
                                    })}
                                </span>
                            </Row>
                            <Row>
                                <span
                                    style={{ color: rootStyles.colors.primary }}
                                >
                                    {channel.displayName}
                                </span>
                            </Row>
                        </Col>
                        {hasManageTwitchPermission ? (
                            <Col xs={2}>
                                <div style={{ justifyContent: "center" }}>
                                    <Icon
                                        size={24}
                                        icon={IconsList.action_remove}
                                        id={`${testIDPrefix}_RemoveTwitch`}
                                    />
                                </div>
                            </Col>
                        ) : null}
                    </Row>
                </div>
            );
        }
    };

    private toggleAboutModal = () => {
        this.setState({
            showAboutModal: !this.state.showAboutModal,
        });
    };

    private toggleAboutSpotlightModal = () => {
        this.setState({
            showAboutSpotlightModal: !this.state.showAboutSpotlightModal,
        });
    };

    private toggleSpotlightLearnMoreModal = () => {
        this.setState({
            showSpotlightLearnMoreModal:
                !this.state.showSpotlightLearnMoreModal,
        });
    };

    private seeLivestreamResources = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Live.twitchSeeResource,
                pageIds.profile,
                this.props.selectedArtistAsin,
                EntityType.ARTIST
            )
        );

        window.open(
            getLocalizedUrl(LocalizedUrls.TwitchLearnMoreUrl),
            "_blank"
        );
    };

    private linkTwitch = () => {
        this.setState({ showAboutModal: false, showTwitchSuccessModal: false });
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Live.linkTwitch,
                pageIds.profile,
                this.props.selectedArtistAsin,
                EntityType.ARTIST
            )
        );

        if (!this.props.oAuthClientIds) {
            this.props.userAction({
                name: "liveScreenLinkTwitchButtonClickFailed",
                dataPoints: new Map<string, string | undefined>([]),
            });
            return;
        }

        window.open(
            getThirdPartyEndpoints().twitchUrl(
                encodeURI(serialize(generateState())),
                this.props.oAuthClientIds.TWITCH
            ),
            "_self"
        );
    };

    private linkingComplete = () => {
        this.setState({ showTwitchSuccessModal: false });
    };

    private unlinkTwitch = () => {
        this.setState({ showUnlinkConfirmationModal: false });

        if (!this.props.selectedArtistAsin || !this.props.teamId) {
            return;
        }

        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Live.unlinkTwitch,
                pageIds.profile,
                this.props.selectedArtistAsin,
                EntityType.ARTIST
            )
        );

        this.props.unlinkTwitch({
            artistAsin: this.props.selectedArtistAsin,
            teamId: this.props.teamId,
            requestPath: paths.profile,
        });
    };

    private onShowBlueprints = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Live.openBlueprints,
                pageIds.profile,
                this.props.selectedArtistAsin,
                EntityType.ARTIST
            )
        );
        this.props.history.push(paths.blueprints);
    };

    private dismissError = () => {
        this.setState({ showError: false });
    };

    private onZoomIn = () => {
        this.setState({ zoom: this.state.zoom + 0.2 });
    };

    private onZoomOut = () => {
        const newZoom = this.state.zoom - 0.2;

        if (newZoom >= 1) {
            this.setState({ zoom: newZoom });
        }
    };

    private onConfirmUploadProfileSuccess = () => {
        this.setState({ showUploadProfileSuccess: false });
    };

    private onConfirmUploadBackgroundSuccess = () => {
        this.setState({ showUploadBackgroundSuccess: false });
    };

    private showUploadProfile = () => {
        this.setState({ showUploadProfile: true });
    };

    private showUploadBackground = () => {
        this.setState({ showUploadBackground: true });
    };

    private onDismissUploadProfile = () => {
        this.setState({ showUploadProfile: false });
    };

    private onDismissUploadBackground = () => {
        this.setState({ showUploadBackground: false });
    };

    private onDismissCropProfile = () => {
        this.setState({ showCropProfile: false });
    };

    private onDismissCropBackground = () => {
        this.setState({ showCropBackground: false });
    };

    private onSelectProfile = () => {
        this.state.profileFileRef.current &&
            this.state.profileFileRef.current.click();
    };

    private cropProfile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        const file = event.target.files && event.target.files[0];

        fileReader.onloadend = () => {
            this.setState({
                selectedImage: fileReader.result,
                showCropProfile: true,
                showUploadProfile: false,
            });
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    private cropBackground = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        const file = event.target.files && event.target.files[0];

        fileReader.onloadend = () => {
            this.setState({
                selectedImage: fileReader.result,
                showCropBackground: true,
                showUploadBackground: false,
            });
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    private onCropChange = (crop: Point) => {
        this.setState({ crop: crop });
    };

    private onZoomChange = (zoom: number) => {
        this.setState({ zoom: zoom });
    };

    private onProfileCropComplete = (
        _croppedArea: Area,
        croppedAreaPixels: Area
    ) => {
        this.setState({
            croppedAreaPixels: croppedAreaPixels,
            showProfileAlert: croppedAreaPixels.width < PROFILE_IMAGE_MIN_WIDTH,
            profileAlertText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.PROFILE_STRINGS,
                stringId: stringIds.Profile.profileResolutionAlert,
            }),
        });
    };

    private onBackgroundCropComplete = (
        _croppedArea: Area,
        croppedAreaPixels: Area
    ) => {
        this.setState({
            croppedAreaPixels: croppedAreaPixels,
            showBackgroundAlert:
                croppedAreaPixels.width < BACKGROUND_IMAGE_MIN_WIDTH,
            backgroundAlertText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.PROFILE_STRINGS,
                stringId: stringIds.Profile.backgroundResolutionAlert,
            }),
        });
    };

    private onSelectBackground = () => {
        this.state.backgroundFileRef.current &&
            this.state.backgroundFileRef.current.click();
    };

    private uploadCroppedImage = (imageType: ImageType) => {
        this.setState({ showLoading: true });
        const image = this.state.selectedImage;
        const crop = this.state.croppedAreaPixels;

        if (!crop || !image) {
            return;
        }

        const croppedImage = this.getCroppedImg(image, crop);
        this.setState({ croppedImageUrl: croppedImage?.toDataURL() });

        croppedImage &&
            croppedImage.toBlob(
                (blob) => {
                    if (
                        !blob ||
                        !blob.type ||
                        !this.props.selectedArtistAsin ||
                        !this.props.teamId
                    ) {
                        return;
                    }

                    if (
                        imageType == ImageType.BackgroundImage &&
                        blob.size > BACKGROUND_IMAGE_MAX_BYTES
                    ) {
                        this.setState({
                            showLoading: false,
                            showBackgroundAlert: true,
                            backgroundAlertText: getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.PROFILE_STRINGS,
                                    stringId:
                                        stringIds.Profile.backgroundSizeAlert,
                                }
                            ),
                        });
                    } else if (
                        imageType == ImageType.ProfileImage &&
                        blob.size > PROFILE_IMAGE_MAX_BYTES
                    ) {
                        this.setState({
                            showLoading: false,
                            showProfileAlert: true,
                            profileAlertText: getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.PROFILE_STRINGS,
                                    stringId:
                                        stringIds.Profile.profileSizeAlert,
                                }
                            ),
                        });
                    } else {
                        const payload: artistImagePayload = {
                            asin: this.props.selectedArtistAsin,
                            fileExtension: blob?.type,
                            imageFile: blob,
                            imageType: imageType,
                            teamId: this.props.teamId,
                            requestPath: paths.profile,
                        };

                        this.props.uploadImage(payload);
                    }
                },
                "image/jpeg",
                IMAGE_COMPRESSION
            );
    };

    private getCroppedImg(imageSrc: string, pixelCrop: Area) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = document.createElement("img");
        image.src = imageSrc;

        canvas.width = image.width;
        canvas.height = image.height;

        if (!ctx) {
            return;
        }

        ctx.drawImage(image, 0, 0);
        const data = ctx.getImageData(0, 0, image.width, image.height);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(data, 0 - pixelCrop.x, 0 - pixelCrop.y);

        return canvas;
    }
}

const toastContainerStyle: React.CSSProperties = {
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
    width: 350,
    maxWidth: 350,
    height: 64,
    borderRadius: 8,
    backgroundColor: "rgba(32, 53, 59, 1)",
};

const toastBodyStyle: React.CSSProperties = {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
};

const textStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    textTransform: "none",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
};

const hrefTextStyle: React.CSSProperties = {
    color: colors.accent,
};

const twitchButtonWidth: React.CSSProperties = {
    maxWidth: 350,
};

const twitchButtonStyle: React.CSSProperties = {
    ...twitchButtonWidth,
    backgroundColor: rootStyles.glassColors.primary2,
    padding: rootStyles.spacers.medium,
    marginLeft: 0,
    minHeight: 96,
    borderRadius: 8,
    justifyContent: "center",
    flexDirection: "row",
};

const rectImage: React.CSSProperties = {
    marginTop: spacers.medium,
    width: 400,
    height: 300,
    marginBottom: spacers.medium,
};

const twitchIconContainerStyle: React.CSSProperties = {
    height: 48,
    width: 48,
    paddingTop: 10,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 24,
    marginRight: rootStyles.spacers.medium,
    backgroundColor: rootStyles.glassColors.primary2,
};

const rowStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "stretch",
    borderBottomStyle: "solid",
    paddingBottom: 0,
    maxHeight: 70,
    marginBottom: 10,
    borderBottomColor: rootStyles.glassColors.primary3,
    borderBottomWidth: 1,
};

const spanStyle: React.CSSProperties = {
    fontSize: rootStyles.webFontSizes.t1,
    textTransform: "capitalize",
    margin: 0,
    padding: 0,
    flex: 2,
    color: rootStyles.colors.primary,
};

const importantDetailsSpanStyle: React.CSSProperties = {
    ...spanStyle,
    ...rootStyles.textStyles.primary,
    flex: 1,
    paddingBottom: 6,
};

const informationCardContainerStyle: React.CSSProperties = {
    ...twitchButtonStyle,
    padding: 0,
    marginTop: 0,
    backgroundColor: "transparent",
};

const clickableTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    color: rootStyles.colors.accent,
    marginTop: 2,
    cursor: "pointer",
};

const videoPlayerContainerStyle: React.CSSProperties = {
    ...twitchButtonWidth,
    flex: 1,
    marginLeft: 0,
    minHeight: 96,
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
};

const FlexibleCol = styled.div`
    max-width: 350px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${rootStyles.spacers.medium}px;
    padding: 0;
    flex-basis: max-content;
    flex-shrink: 0;
    position: relative;
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        flex-shrink: 1;
    }
    @media (min-width: ${rootStyles.breakpoints.lg}px) {
        min-width: 350px;
    }
`;

function mapStateToProps(state: RootState): StateProps {
    return profilePageSelector(state, paths.profile);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        linkTwitch: (payload: linkTwitchChannelPayload) =>
            dispatch(twitchActions.linkTwitchChannel(payload)),
        unlinkTwitch: (payload: unlinkTwitchChannelPayload) =>
            dispatch(twitchActions.unlinkTwitchChannel(payload)),
        uploadImage: (payload: artistImagePayload) =>
            dispatch(artistImageActions.artistUpload(payload)),
        hydrateAsin: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProfileScreen)
);
